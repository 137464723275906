<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show">
    <template v-if="OrderType !== 'delivery'">
      <CSidebarBrand style="padding: 12px">
        <div class="row text-black c-sidebar-brand-full mt-4">
          <div class="col-4">
            <img v-c-emit-root-event:toggle-sidebar-mobile :src="logo" class="c-avatar-img" width="100%" alt=""
              onerror="this.onerror=null;this.src='cdn-icons-png.flaticon.com/512/1900/1900765.png'"
              v-on:click="goBackHomePage()" />
          </div>
          <div class="col-8 pt-1">
            <!-- <strong> {{ shopName }} </strong> <br> -->
            <strong class="h5">{{ $t('iAm') }} {{ name }} 👋 </strong><br>
            <span>
              {{ $t('tableNumber') }}: <b>{{ tableName }}</b>
            </span>
            <br>

          </div>
        </div>
        <div class="c-sidebar-brand-minimized">
          <img :src="logo" width="20" alt="" />
        </div>
      </CSidebarBrand>
      <CSidebarNav style="font-size: large;" class="mt-3">
        <CSidebarNavItem v-c-emit-root-event:toggle-sidebar-mobile :name="$t('menuList')" to="/dine-in/menulists"
          fontIcon="fi fi-rr-home">
        </CSidebarNavItem>
        <CSidebarNavItem v-c-emit-root-event:toggle-sidebar-mobile :name="$t('selectedItemNav')"
          to="/dine-in/selecteditem" fontIcon="fi fi-rr-shopping-basket">
        </CSidebarNavItem>
        <CSidebarNavItem v-c-emit-root-event:toggle-sidebar-mobile :name="$t('orderItemNav')" to="/dine-in/mycart"
          fontIcon="fi fi-rr-utensils">
        </CSidebarNavItem>
      </CSidebarNav>
      <CSidebarMinimizer class="d-md-down-none" @click.native="minimize = !minimize" />
      <footer class="footer">
        <div class="row p-4">
          <div class="col-6 p-1">
            <CButton class="sidebar-btn">
              <LanguageSwitch :showName="true"></LanguageSwitch>
            </CButton>
          </div>
          <div class="col-6 p-1">
            <div class="position-relative">
              <CButton @click="generateQR()" v-c-emit-root-event:toggle-sidebar-mobile class="sidebar-btn"
                data-toggle="modal" data-target="#qr-modal">
                <img src="/img/qr.png" width="46%">
                <h5 class="mt-3">{{ $t('share') }} QR</h5>
              </CButton>
              <span class="badge badge-danger new-badge">new</span>
            </div>
          </div>

        </div>

        <div class="modal fade" id="qr-modal">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="background-color: unset;">
              <div class="small-modal" style="background-color: white;">
                <div class="p-2 pt-3 text-dark text-center">
                  <h4 class="font-weight-bold">{{ $t('shareQRtoFriend') }}</h4>

                  <h5 class="font-weight-bold">{{ tableName }}</h5>

                  <div class="qr-container position-relative">
                    <vue-qrcode ref="qrcode" class="img-fluid" :value="qrtable" style="width: 100%" />
                    <img :src="logo" class="qr-logo" />
                  </div>
                  <div class="mt-2 mb-2">
                    <CButton style="width: 40%;" class="btn-color-theme m-2" @click="shareQRCode">{{ $t('share') }}
                    </CButton>
                    <CButton style="width: 40%;" data-dismiss="modal" class="btn-color-theme-outline m-2">{{
    $t('closeTab') }}</CButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </template>

    <template v-if="OrderType == 'delivery'">
      <CSidebarBrand style="padding: 12px">
        <div class="row text-black c-sidebar-brand-full mt-4">
          <div class="col-4">
            <img :src="logo" class="circle-avatar-img" width="100%" alt=""
              onerror="this.onerror=null;this.src='cdn-icons-png.flaticon.com/512/1900/1900765.png'"
              v-on:click="goBackHomePage()" v-c-emit-root-event:toggle-sidebar-mobile />
          </div>
          <div class="col-8 pt-3">
            <strong class="h5"> {{ shopName }} </strong> <br>
          </div>
        </div>
        <div class="c-sidebar-brand-minimized">
          <img :src="logo" width="20" alt="" />
        </div>
      </CSidebarBrand>
      <CSidebarNav style="font-size: large;" class="mt-3">
        <CSidebarNavItem v-c-emit-root-event:toggle-sidebar-mobile :name="$t('menulists')" to="/delivery/menulists"
          fontIcon="fi fi-rr-hamburger-soda">
        </CSidebarNavItem>
        <CSidebarNavItem v-c-emit-root-event:toggle-sidebar-mobile :name="$t('purchaseHistory')" to="/delivery/history"
          fontIcon="fi fi-rr-shopping-basket">
        </CSidebarNavItem>
        <CSidebarNavItem v-c-emit-root-event:toggle-sidebar-mobile :name="$t('notification')"
          to="/delivery/notification" fontIcon="fi fi-rr-bell-ring">
        </CSidebarNavItem>
        <CSidebarNavItem v-c-emit-root-event:toggle-sidebar-mobile :name="$t('myAccount')" to="/delivery/user"
          fontIcon="fi fi-rr-user">
        </CSidebarNavItem>
      </CSidebarNav>
      <CSidebarMinimizer class="d-md-down-none" @click.native="minimize = !minimize" />
      <footer class="footer">
        <div class="ml-auto">
          <div class="m-2">
            <LanguageSwitch></LanguageSwitch>
          </div>
        </div>
      </footer>
    </template>
  </CSidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import LanguageSwitch from '@/components/AppLanguageSwitch'
import '@/util/menu.css'
import VueQrcode from 'vue-qrcode'

export default {
  components: {
    LanguageSwitch,
    VueQrcode
  },
  data() {
    return {
      minimize: false,
      show: 'responsive',
      showDropdown: false,
      OrderType: '',
      qrtable: ''
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId() {
      return `${localStorage.getItem('shopObjectId')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    shopName() {
      if (this.shop) {
        return this.shop.shopName
      } else {
        return ''
      }
    },
    tableName() {
      let table = JSON.parse(localStorage.getItem('table')) || {}
      return table.name || ''
    },
    logo() {
      if (this.shop?.remoteImagePath != null) {
        return this.shop.remoteImagePath || ''
      } else {
        return 'https://cdn-icons-png.flaticon.com/512/1900/1900765.png'
      }
    },
    name() {
      return localStorage.getItem('name') || ''
    }
  },
  created() {
    this.OrderType = this.getPathSegment(0)
  },
  methods: {
    trackButtonClick(buttonLabel) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'click', {
          event_label: buttonLabel,
        });
      } else {
        console.error('gtag is not defined.');
      }
    },
    getPathSegment(segmentIndex) {
      const urlObject = new URL(window.location.href);
      const pathSegments = urlObject.pathname.split('/').filter(segment => segment);
      return pathSegments[segmentIndex];
    },
    changeLocale(locale) {
      this.trackButtonClick('SidebarOrder/Change_Lang_to_' + locale)
      this.$i18n.locale = locale
      this.showDropdown = false
    },
    goBackHomePage() {
      if (this.OrderType !== 'delivery') {
        this.$router.push("/dine-in/home?uid=" + localStorage.uid +
          "&shopObjectId=" + localStorage.shopObjectId +
          "&tableObjectId=" + localStorage.tableObjectId +
          "&pax=" + localStorage.pax +
          "&time=" + localStorage.timestamp)
      } else {
        this.$router.push("/delivery/home")
      }
    },
    generateQR() {
      let mainurl = process.env.VUE_APP_WEB_URL + '/dine-in/home?'
      const d = new Date()
      let time = d.getTime()

      this.qrtable =
        mainurl +
        'uid=' +
        this.uid +
        '&shopObjectId=' +
        this.shopObjectId +
        '&tableObjectId=' +
        localStorage.tableObjectId +
        '&pax=' +
        localStorage.pax +
        '&time=' +
        time
      console.log(this.qrtable)
    },
    async shareQRCode() {
      try {
        if (navigator.share) {
          await navigator.share({
            title: '',
            text: '',
            url: this.qrtable // Share the URL
          });
        } else {
          alert('Web Share API is not supported in your browser.');
        }
      } catch (error) {
        console.error('Error sharing QR code URL:', error);
      }
    }

  },

  mounted() {
    document.title = this.shopName + ' by SilomPOS'
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })
  },
}
</script>

<style>
html:not([dir='rtl']) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link .c-sidebar-nav-icon,
html:not([dir='rtl']) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  margin-left: -30px;
}

.iconsize {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  height: 0.55rem;
  font-size: 0.09375rem;
  text-align: center;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  fill: currentColor;
}

.c-sidebar .c-sidebar-nav-link.c-active {
  background-color: var(--bg-color-theme);
  color: white;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background-color: var(--bg-color-theme);
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: white;
}

.c-sidebar-backdrop.c-show {
  z-index: 2200;
}

.qr-container {
  display: inline-block;
  position: relative;
}

.position-relative {
  position: relative;
}

.new-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--bg-color-theme);
  color: white;
  padding: 6px 6px;
  font-size: 15px;
  border-radius: 20px;
  z-index: 10;
}

.qr-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
  border-radius: 50%;
  aspect-ratio: 1/1;
  pointer-events: none;
}

.colored-image {
  width: 46%;
  filter: sepia(1) saturate(10000%) hue-rotate(90deg);
  /* Adjust to match your color */
}

.circle-avatar-img {
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}
</style>
